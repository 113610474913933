import { LeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";

const BackButton = ({ className }) => {
  const history = useHistory();
  return (
    <Button
      className={
        "flex items-center justify-center text-sm mr-1 border-none shadow-none cursor-pointer hover:text-indigo-300 mb-0 p-0 " +
        className
      }
      type="ghost"
      onClick={() => history.go(-1)}
    >
      <LeftOutlined className="-mr-1" style={{ marginBottom: "1px" }} /> Back
    </Button>
  );
};

export default BackButton;
