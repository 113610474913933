import React from "react";
import { Layout, Menu, Button, Dropdown } from "antd";
import { useSelector } from "react-redux";
import { DownOutlined } from "@ant-design/icons";
import ImageAvatar from "../components/Avatars/Avatar";

const { Header } = Layout;

const Navbar = ({ accountInfo, changePassword, logout }) => {
  const user = useSelector((s) => s.auth?.user);
  const role = user?.department || user?.role?.replaceAll("_", " ");

  const menu = (
    <Menu className="min-w-[150px]">
      <Menu.Item key="1" className="detCol">
        <Button
          type="text"
          className="text-black specbtn hover:text-black"
          onClick={() => accountInfo(true)}
        >
          Account Information
        </Button>
      </Menu.Item>

      <Menu.Item key="2" className="detCol">
        <Button
          type="text"
          className="text-black specbtn hover:text-black"
          onClick={() => changePassword(true)}
        >
          Change Password
        </Button>
      </Menu.Item>
      <Menu.Item key="3" className="detCol">
        <Button
          type="text"
          className="text-black specbtn hover:text-black"
          onClick={() => logout(true)}
        >
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Header
        className="site-layout-background bg-white border-b border-gray-200 border-opacity-70 border-solid 
        flex items-center justify-between mk-nav md:px-7 px-5"
        theme="light"
      >
        <div className="xl:hidden block">
          {/* <img className="w-20" src={logo} alt="logo" /> */}
        </div>
        <p className="mikro-gray-2 text-lg font-bold capitalize">
          {user?.username}
        </p>
        <div className="flex items-center ml-auto">
          {/* <Dropdown overlay={notify} placement="bottomCenter">
            <button className="relative rounded-full w-8 h-8 flex items-center justify-center bg-gray-200 ">
              <img className="w-4" src={notification} alt="notfications" />
              <span className="rounded-full w-2 h-2 bg-red-500 absolute bottom-2 right-1"></span>
            </button>
          </Dropdown> */}
          {/* <div className="rounded-full w-8 h-8 flex items-center cursor-pointer justify-center bg-gray-200 ml-3">
            <SettingFilled className="text-lg mb-1" />
          </div> */}
          <Dropdown overlay={menu} overlayClassName="w-3" placement="bottom">
            <div className="flex items-center cursor-pointer">
              <ImageAvatar
                className="ml-3"
                size={30}
                profileImageId={user?.profileImageId || ""}
              />
              <div className="ml-3">
                <p className="text-base font-semibold mb-0 leading-none capitalize">
                  {user?.firstName ?? " "} {user?.lastName ?? " "}
                </p>
                <p className="mikro-gray text-xs capitalize">{role}</p>
              </div>
              <DownOutlined className="ml-3 text-xs text-gray-400" />
            </div>
          </Dropdown>
        </div>
      </Header>
    </>
  );
};

export default Navbar;
