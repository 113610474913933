import payoutTypes from "./types";

const initialState = {
  payoutRef: null,
  show: false,
};

const payoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case payoutTypes.PAYOUT_REFERENCE:
      return {
        ...state,
        show: true,
        payoutRef: action.payload,
      };
    case payoutTypes.CLOSE_PAYOUT_REFERENCE:
      return {
        ...state,
        show: false,
        payoutRef: null,
      };

    default:
      return state;
  }
};

export default payoutReducer;
