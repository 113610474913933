import { terminalTypes } from "./types";

const initialState = {
  modal: false,
  data: null,
  tab: 0,
};

const terminalReducer = (state = initialState, action) => {
  switch (action.type) {
    case terminalTypes.OPEN_TERMINAL_MODAL:
      return { ...state, modal: true, data: action.payload };

    case terminalTypes.CLOSE_TERMINAL_MODAL:
      return { ...state, modal: false, data: null };

    case terminalTypes.CHANGE_PANEL:
      return { ...state, tab: action.payload };
    default:
      return state;
  }
};

export default terminalReducer;
