import types from "./types";
import HttpRequests from "../../../common/utils/HttpRequests";
import url from "../../../common/utils/url";
import { store } from "../../../store";
import axios from "axios";
import storage from "redux-persist/lib/storage";

const Request = new HttpRequests();
const baseUrl = url.account;
const { dispatch } = store;

const setToken = (token) => {
  return Promise.resolve().then(() => {
    localStorage.setItem("grupp.token", token);
  });
};

export const setFormInput = (values) => {
  return { type: types.FORM_INPUT, payload: values };
};

export const changeCurrency = (value) => {
  return dispatch({ type: types.UPDATE_CURRENCY, payload: value });
};

export const loginUser = async (data) => {
  try {
    let login = await Request.postWithoutHeader(
      `/${baseUrl}/login`,
      null,
      data
    );
    if (login?.status) {
      localStorage.removeItem("grupp.token");
      await setToken(login?.data?.accessToken);
    }
    await Promise.resolve(
      dispatch({ type: types.SET_USER, payload: login?.data })
    )
      .then(
        dispatch({ type: types.SET_CATEGORY, payload: login?.data?.category })
      )
      .then(dispatch({ type: types.LOGIN_USER }));
    return Promise.resolve(login);
  } catch (err) {
    dispatch({ type: types.SET_USER, payload: { email: data.username } });
    return Promise.reject(err);
  }
};

export const logoutUser = (history) => async (dispatch) => {
  try {
    dispatch({ type: types.SET_NEW_USER, payload: false });
    dispatch({ type: types.SET_USER_TYPE, payload: "" });
    dispatch({ type: types.LOGOUT_USER });
    dispatch({ type: types.SET_USER, payload: {} });
    localStorage.removeItem("persist:root");
    storage.removeItem("persist:root");
    storage.removeItem("grupp.token");
    localStorage.removeItem("grupp.token");
    history.push("/login");
  } catch (err) {}
};

export const registerUser = async (data) => {
  try {
    let user = await Request.postWithoutHeader(
      `/${baseUrl}/register`,
      null,
      data
    );
    if (user.status) {
      dispatch({
        type: types.SET_USER,
        payload: {
          id: user.data?.id,
          email: user.data?.email,
          firstName: user.data?.firstName,
          lastName: user.data?.lastName,
          verificationId: user.data?.verificationId,
        },
      });
      dispatch({ type: types.SET_NEW_USER, payload: true });
      dispatch({ type: types.FORM_INPUT, payload: {} });
    }
    return Promise.resolve(user);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const resendVerificationEmail = async (data) => {
  try {
    let res = await Request.postWithoutHeader(
      `/${baseUrl}/resend-verification-email`,
      null,
      data
    );
    dispatch({
      type: types.SET_USER,
      payload: { verificationId: res.data?.verificationId },
    });
    if (res.status) return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const sendVerificationOtp = async (data) => {
  try {
    let res = await Request.postWithoutHeader(
      `/${baseUrl}/verify-email`,
      null,
      data
    );
    if (res.status)
      return Promise.resolve({ message: "Your email has been verified" });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const sendPasswordResetRequest = async (data) => {
  try {
    let res = await Request.postWithoutHeader(
      `/${baseUrl}/forgot-password`,
      null,
      data
    );
    if (res.status) return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const sendPasswordReset = async (data) => {
  try {
    let res = await Request.postWithoutHeader(
      `/${baseUrl}/reset-password`,
      null,
      data
    );
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchStatesList = async () => {
  try {
    let states = await Request.get(`/location`);
    dispatch({ type: types.SET_STATE, payload: states.data });
    return Promise.resolve(states);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchLgaList = async (state) => {
  try {
    let lga = await Request.get(`/location/${state.toString()}`);
    dispatch({ type: types.SET_LGA, payload: lga.data });
    return Promise.resolve(lga);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getProfile = async () => {
  try {
    let profile = await Request.get(`/${url.base}/staff/profile`);
    if (profile.status);
    //I am just running a test these should be removed
    //dispatch({ type: types.SET_PROFILE, payload: profile.data });
    return Promise.resolve(profile);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updatePassword = async (data) => {
  try {
    let update = await Request.put(
      `${url.base}/account/change-password`,
      null,
      data,
      dispatch
    );
    if (update.status) return Promise.resolve(update);
  } catch (err) {
    if (err.response)
      return Promise.reject({ message: err.response.data.message });
    return Promise.reject(err);
  }
};

export const updateUsername = async (data) => {
  try {
    const username = await Request.put(
      `${url.base}/account/username`,
      null,
      data
    );
    return Promise.resolve(username);
  } catch (error) {
    return Promise.reject({ message: error.response.data.message });
  }
};

export const validateCheck = async (option, value) => {
  try {
    const check = await Request.post(
      `${url.base}/account/${option}/validate`,
      null,
      { value: value },
      dispatch
    );

    if (check.status) return Promise.resolve(check);
  } catch (error) {
    return Promise.reject({ message: error.response.data.message || error });
  }
};

export const setValidPhoneNumberState = (state) => {
  return { type: types.SET_VALIDATE_PHONE, payload: state };
};

export const getBankerInfo = async () => {
  try {
    const profile = await Request.get(`/${baseUrl}/me`, null, null, dispatch);
    return Promise.resolve(profile);
  } catch (err) {
    if (err.response)
      return Promise.reject({ message: err.response.data.message });
    return Promise.reject(err);
  }
};

export const confirmPassword = async (data) => {
  try {
    let pass = await axios.post(
      `${process.env.REACT_APP_API}/${baseUrl}/login`,
      data
    );
    if (pass.status) return Promise.resolve(pass);
  } catch (err) {
    if (err.response)
      return Promise.reject({ message: err.response?.data?.message });
    return Promise.reject(err);
  }
};

export const validateBvn = async (value) => {
  try {
    let check = await Request.put(
      `${url.base}/account/validate-bvn`,
      null,
      value,
      dispatch
    );
    if (check.status) return Promise.resolve(check);
  } catch (err) {
    if (err.response)
      return Promise.reject({ message: err.response.data.message });
    return Promise.reject(err);
  }
};

export const confirmRequest = async (data) => {
  try {
    let confirm = await axios.post(
      `${process.env.REACT_APP_API}/${baseUrl}/authorize`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("grupp.token")}`,
          browserId: `${localStorage.getItem("grupp.browserId")}`,
        },
      }
    );
    return Promise.resolve(confirm);
  } catch (err) {
    if (err.response) {
      return Promise.reject({ message: err.response?.data.message });
    }
    return Promise.reject(err);
  }
};

export const browserSignInRequest = async (data) => {
  try {
    let browser = await axios.post(
      `${process.env.REACT_APP_API}/browser/request`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("grupp.token")}`,
        },
      }
    );
    return Promise.resolve(browser);
  } catch (err) {
    if (err.response) {
      return Promise.reject({ message: err.response?.data.message });
    }
    return Promise.reject(err);
  }
};

export const browserValidation = async (data) => {
  try {
    let browser = await axios.post(
      `${process.env.REACT_APP_API}/browser/validate`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("grupp.token")}`,
        },
      }
    );
    return Promise.resolve(browser);
  } catch (err) {
    if (err.response) {
      return Promise.reject({ message: err.response?.data.message });
    }
    return Promise.reject(err);
  }
};

export const validateSubdomain = async (data) => {
  try {
    let valid = await Request.post(`/domain/validate`, null, data, dispatch);
    return Promise.resolve(valid);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const enableAutoSettlement = async (status) => {
  try {
    let result = await Request.put(
      `/${baseUrl}/${status}/auto-settlement`,
      null,
      null,
      dispatch
    );
    if (result.status) {
      dispatch({ type: types.UPDATE_USER, payload: result.data });
      return Promise.resolve(result);
    }
  } catch (err) {
    if (err.response)
      return Promise.reject({
        message: err.response.data?.message,
      });
    return Promise.reject({
      message: err.message,
    });
  }
};

export const handleRcNumberVerification = async (data) => {
  try {
    const verificationResponse = await Request.post(
      `/${baseUrl}/registration-number`,
      null,
      data
    );
    return Promise.resolve(verificationResponse);
  } catch (err) {
    return Promise.reject({
      message: err.response?.data.message || "Failed to resolve BusinessReg No",
    });
  }
};
