import React from "react";
import { Button } from "antd";

export const BlackButton = ({
  className = "",
  icon,
  size = "medium",
  onClick,
  children,
  loading,
  disabled,
}) => {
  return (
    <Button
      className={`${className} inter rounded-lg text-white border-0 bg-[#231F20] active:bg-[#231F20] focus:bg-[#231F20] hover:bg-[#231F20]`}
      onClick={onClick}
      loading={loading}
      style={{
        color: "#fff",
      }}
      icon={icon}
      size={size}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export const TransparentButton = ({
  className = "",
  icon,
  size = "medium",
  onClick,
  children,
  loading,
  disabled,
}) => {
  return (
    <Button
      className={`${className} border-[#231F20] inter rounded-lg text-black border bg-[#FFFFFF] active:bg-[#FFFFFF] focus:bg-[#FFFFFF] hover:bg-[#FFFFFF]`}
      onClick={onClick}
      loading={loading}
      icon={icon}
      size={size}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export const LightButton = ({
  className = "",
  icon,
  size = "medium",
  onClick,
  children,
  loading,
  disabled,
  type,
}) => {
  return (
    <Button
      className={`${className} !text-[#475467] h-full flex justify-center items-center font-extrabold py-3 px-4 gap-2 border-[#d0d5dd] rounded-xl bg-[#FFFFFF] active:bg-[#FFFFFF] focus:bg-[#FFFFFF] hover:bg-[#FFFFFF]`}
      onClick={onClick}
      loading={loading}
      icon={icon}
      size={size}
      disabled={disabled}
      htmlType={type}
    >
      {children}
    </Button>
  );
};
