const settingsActionTypes = {
  SET_TAB: "SET SETTINGS TAB",
  SET_SELECTED_MEMBER: "SET SELECTED MEMBER",
  SET_SELECTED_MEMBER_ID: "SET SELECTED MEMBER ID",
  TOGGLE_EDIT_MEMBER: "TOGGLE EDIT MEMBER",
  TOGGLE_DELETE_MEMBER: "TOGGLE DELETE MEMBER",
  TOGGLE_ACTIVATE_MEMBER: "TOGGLE ACTIVATE MEMBER",
  TOGGLE_DEACTIVATE_MEMBER: "TOGGLE DEACTIVATE MEMBER",
};
export default settingsActionTypes;
