import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import Logo from "../../assets/logo/Grupp-logo.svg";
import DefaultBG from "../../assets/backgrounds/auth_bg.png";

const AuthShell = ({ children, background, title, description }) => {
  const location = useLocation();
  const isAggregator = useMemo(
    () => window.location.hostname.includes("aggregator"),
    []
  );

  return (
    <div
      className="flex xl:flex-row flex-col w-full xl:h-screen min-h-screen"
      style={{ minHeight: "800px" }}
    >
      <motion.div
        key={location.pathname}
        initial="pageInitial"
        animate="pageAnimate"
        variants={{
          pageInitial: {
            opacity: 0.5,
          },
          pageAnimate: {
            opacity: 1,
          },
        }}
        className={`xl:w-1/2 w-full xl:h-full bg-half relative lg:block hidden`}
      >
        <img
          className="w-full h-full absolute top-0 left-0 z-1"
          src={background || DefaultBG}
          alt="background"
        />
        <div className=" z-2 w-20 block xl:hidden absolute top-5 right-7">
          {/* <img src={Logo} alt="logo" /> */}
        </div>
        <div className="xl:mt-48 mt-12 m-auto md:w-9/12 w-11/12">
          <h3 className="text-black xl:text-5xl text-3xl font-black mb-5">
            {/* {title} */}
          </h3>
          <p className="text-base mikro-gray-2 md:w-4/5 w-11/12">
            {/* {description} */}
          </p>
        </div>
      </motion.div>

      <motion.div
        key={location.pathname / 2}
        initial="pageInitial"
        animate="pageAnimate"
        variants={{
          pageInitial: {
            opacity: 0,
            x: 500,
          },
          pageAnimate: {
            opacity: 1,
            x: 0,
          },
        }}
        className="xl:w-1/2 w-full lg:h-screen h-full relative py-10 md:pb-16 pb-20 overflow-y-auto"
      >
        <div className="px-2 2xl:w-7/12 xl:w-2/3 md:w-3/4 w-10/12 m-auto">
          <div className=" w-24 mb-7">
            {!isAggregator && (
              <Link to="/">
                <img src={Logo} alt="logo" />
              </Link>
            )}
          </div>
          {children}
        </div>
      </motion.div>
    </div>
  );
};

export default AuthShell;
