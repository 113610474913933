import React, { useState } from "react";
import AuthButton from "../Buttons/AuthButton";
import CustomPasswordInput from "../forms/CustomPasswordInput";
import ModalShell from "./ModalShell";
import { Form, notification } from "antd";
import { useFormValidation } from "../../utils/Hooks";
import SuccessIcon from "../../../assets/svg/success.svg";
import { updatePassword } from "../../../modules/Auth/store/actions";

const ChangePasswordModal = ({ show, onCancel }) => {
  const [form] = Form.useForm();
  const [successMsg, setSuccessMsg] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  async function onConfirm(values) {
    setSubmitting(true);
    try {
      let updateUserPassword = await updatePassword(values);
      if (updateUserPassword.status) {
        setSuccessMsg(true);
        setTimeout(() => {
          onCancel();
        }, 1500);
      }
    } catch (err) {
      notification.error({
        message: "Error",
        description: err.message,
      });
    }
    setSubmitting(false);
  }

  const { fields, valid, validate } = useFormValidation(form, []);

  return (
    <ModalShell show={show} cancel={onCancel}>
      {successMsg ? (
        <div className="flex flex-col items-center">
          <img className="my-5" src={SuccessIcon} alt="in-progress" />

          <p className="text-2xl font-bold mb-2">
            Password Changed Successfully
          </p>

          <p className="mikro-gray text-xs text-center mb-10">
            You have successfully changed your password.
          </p>
          <div className="flex w-full justify-between">
            <AuthButton htmlType="submit" className="w-full" onClick={onCancel}>
              THANKS
            </AuthButton>
          </div>
        </div>
      ) : (
        <div>
          <p className="text-3xl font-bold mb-4">Change Password</p>

          <p className="mikro-gray text-xs mb-10">
            Enter old password and create and confirm new password to continue
          </p>
          <Form
            form={form}
            layout="vertical"
            onFinish={onConfirm}
            onValuesChange={validate}
          >
            <Form.Item
              name="oldPassword"
              placeholder="Old Password"
              rules={[{ required: true, message: "Enter Old Password!" }]}
            >
              <CustomPasswordInput
                label="Enter Old Password"
                placeholder="Enter Old Password"
                hasValue={fields?.oldPassword?.length}
              />
            </Form.Item>
            <Form.Item
              name="newPassword"
              placeholder="New Password"
              rules={[{ required: true, message: "Enter New Password!" }]}
            >
              <CustomPasswordInput
                label="Create New Password"
                placeholder="Create New Password"
                hasValue={fields?.newPassword?.length}
              />
            </Form.Item>
            <Form.Item
              name="confirmNewPassword"
              placeholder="Confirm New Password"
              rules={[
                {
                  required: true,
                  message: "Enter Password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("passwords does not match")
                    );
                  },
                }),
              ]}
            >
              <CustomPasswordInput
                label="Confirm New Password"
                placeholder="Confirm New Password"
                hasValue={fields?.confirmPassword?.length}
              />
            </Form.Item>

            <div className="flex w-full justify-between mt-12">
              <AuthButton
                htmlType="submit"
                className="w-full"
                disabled={!valid}
                loading={submitting}
              >
                RESET PASSWORD
              </AuthButton>
            </div>
          </Form>
        </div>
      )}
    </ModalShell>
  );
};
export default ChangePasswordModal;
