import numeral from "numeral";
import moment from "moment";

export const premiumFeatureRecord = {
  TMS: { subscriptionStatus: null },
  ANALYTICS: { subscriptionStatus: null },
};

export const initialState = {
  startTime: moment().startOf("day").format("YYYY-MM-DD"),
  endTime: moment().endOf("day").format("YYYY-MM-DD"),
};

export const lastTwoMonths = {
  startTime: moment()
    .subtract(2, "months")
    .startOf("month")
    .format("YYYY-MM-DD"),
  endTime: moment().endOf("day").format("YYYY-MM-DD"),
};

export const lastSevenDays = {
  startTime: moment().subtract(6, "days").startOf("day").format("YYYY-MM-DD"),
  endTime: moment().endOf("day").format("YYYY-MM-DD"),
};

export const lastTenDays = {
  startTime: moment().subtract(12, "days").startOf("day").format("YYYY-MM-DD"),
  endTime: moment().endOf("day").format("YYYY-MM-DD"),
};


export const TransactionLevel = {
  ONE: "ONE",
  TWO: "TWO",
  THREE: "THREE",
};

export const TerminalFilterCategory = {
  TRANSACTION: "TRANSACTION",
  ACTIVITY: "ACTIVITY",
};

export const userTypesOption = [
  { value: "", label: "All" },
  { value: "AGENT", label: "Agent" },
  { value: "MERCHANT", label: "Merchant" },
  { value: "AGENT,MERCHANT", label: "Agent and Merchant" },
  { value: "SALES_AGGREGATOR", label: "Aggregator" },
  { value: "BANKER", label: "Banker" },
];

export const commercialUserTypes = [
  { value: "", label: "All" },
  { value: "MERCHANT", label: "Merchant" },
  { value: "BANKER", label: "Banker" },
];

export const AccountTypeStatus = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  DECLINED: "DECLINED",
  NOT_APPLICABLE: "NOT_APPLICABLE",
};
export const KycApprovalStatus = {
  PENDING: "PENDING",
  APPROVED: "APPROVE",
  DECLINED: "DECLINE",
};

export const AccountType = {
  INDIVIDUAL: "INDIVIDUAL",
  BUSINESS: "BUSINESS",
};

export const UserType = {
  BUSINESS: "BUSINESS",
  CUSTOMER: "CUSTOMER",
  AGGREGATOR: "AGGREGATOR",
  BANKER: "BANKER",
  SALES_AGGREGATOR: "SALES_AGGREGATOR",
  AGENT: "AGENT",
  MERCHANT: "MERCHANT",
};

export const TerminalVendor = {
  ITEX: "ITEX",
  ETOP: "ETOP",
  GA: "GA",
  FLW: "FLW",
};

export const PremiumFeature = {
  TMS: "TMS",
  ANALYTICS: "ANALYTICS",
};

export const TransactionType = {
  AIRTIME: "AIRTIME",
  WALLET_TOP_UP: "WALLET_TOP_UP",
  WITHDRAWAL: "WITHDRAWAL",
  CABLE_TV: "CABLE_TV",
  P2P: "P2P",
  PHCN: "PHCN",
  USSD_WITHDRAWAL: "USSD_WITHDRAWAL",
  DATA: "DATA",
  TRANSFER: "TRANSFER",
  DISBURSEMENT: "DISBURSEMENT",
  COMMISSION: "COMMISSION",
  SETTLEMENT: "SETTLEMENT",
  REVENUE: "REVENUE",
  PAYOUT: "PAYOUT",
  PURCHASE: "PURCHASE",
  LOAN: "LOAN",
  BETTING: "BETTING",
};

export const DateOption = {
  MONTH: "MONTH",
  DAY: "DAY",
};

export const InitialTransactionStats = {
  successful: {
    value: 0,
    count: 0,
  },
  pending: {
    value: 0,
    count: 0,
  },
  failed: {
    value: 0,
    count: 0,
  },
  refunded: {
    value: 0,
    count: 0,
  },
  total: {
    value: 0,
    count: 0,
  },
};

export const TerminalType = {
  TOPWISE_T1: "TOPWISE_T1",
  TOPWISE_MP35P: "TOPWISE_MP35P",
  HORIZONPAY_K11: "HORIZONPAY_K11",
  HORIZONPAY_T50: "HORIZONPAY_T50",
  MOREFUN_H9: "MOREFUN_H9",
  SUNYARD_I80: "SUNYARD_I80",
  NEWLAND_N910: "NEWLAND_N910",
  NEWPOS_7210: "NEWPOS_7210",
  NEWPOS_9220: "NEWPOS_9220",
  TELPO_TPS900: "TELPO_TPS900",
  TIANYU_P30: "TIANYU_P30",
};

export const FormatMoney = (value, useDecimals, currencyType) => {
  let currency =
    currencyType === CurrencyPricingType.USD
      ? "$"
      : process.env.REACT_APP_COUNTRY_CURRENCY;

  if (!value) return `${currency} 0`;

  if (typeof value === "string") value = Number(value);

  return `${currency} ${numeral(value).format(
    useDecimals ? "0,0,0.00" : "0,0,0"
  )}`;
};

export const FormatMoneyString = (value, useDecimals) => {
  let currency = process.env.REACT_APP_COUNTRY_CURRENCY
    ? process.env.REACT_APP_COUNTRY_CURRENCY + " "
    : "";

  return currency + value;
};

export const FormatNumber = (inputValue) => {
  if (!inputValue) return 0;
  const stringValue = String(inputValue);

  return stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const HumanFriendlyDate = (dateTime) => {
  if (!dateTime || dateTime === "NA") {
    return "N/A";
  }

  const _dateTime = moment(dateTime);
  return `${_dateTime.format("Do MMM, YYYY")}`;
};

export const HumanFriendlyDateTime = (dateTime, format) => {
  if (!dateTime || dateTime === "NA") {
    return "N/A";
  }
  let _dateTime;

  if (format) {
    _dateTime = moment(dateTime, format);
  } else {
    _dateTime = moment(dateTime);
  }

  return _dateTime.format("Do MMM, YYYY, hh:mma");
};

export function addOneMonth(date) {
  var newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + 1);
  return newDate;
}

/**
 *
 * @param {Date} date1
 * @param {Date} date2
 * @param {"year"|"month"|"day"} limit
 * @returns
 */
export function areDatesSame(
  date1 = new Date(),
  date2 = new Date(),
  limit = "day"
) {
  let isSame = false;

  if (limit === "year") {
    isSame = date1.getFullYear() === date2.getFullYear();
  } else if (limit === "month") {
    isSame =
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth();
  } else if (limit === "day") {
    isSame =
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();
  }

  return isSame;
}

export function isDateWithinRange(date, startDate, endDate) {
  return date >= startDate && date <= endDate;
}

export const removeGMTFromTimeFormat = (value) => {
  if (!value) return "N/A";
  value = new Date(value).toDateString();
  return value;
};

export const isEmptyObj = (value) => {
  if (typeof value === "object") return Object.keys(value).length !== 0;
  return value;
};

export const capitalizeFirstLetter = (str) => {
  const result = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  return result.replaceAll("_", " ");
};

export const modifiedList = (list) => {
  const result = list.map((data) => ({
    name: `${data.firstName} ${data.lastName}`,
    phoneNumber: data.phoneNumber,
    amount: FormatMoney(data.totalAmount, true),
    totalPaid: FormatMoney(data.totalPaid, true),
    interest: FormatMoney(data.totalAmount, true),
    totalOwned: FormatMoney(data.totalOwned, true),
    repaymentStatus: data.repaymentStatus,
    repaymentDate: HumanFriendlyDate(list.repaymentDate),
    timeCreated: HumanFriendlyDate(data.timeCreated),
  }));
  return result;
};

export const checkRCPrefix = (str) => {
  if (!str.startsWith("RC")) {
    return "RC" + str;
  } else {
    return str;
  }
};

export const testingBits = (transactionTypes, category) => {
  if (category.includes("Issues")) return null;
  if (transactionTypes.length > 0) {
    let res = transactionTypes.find((x) =>
      category.toUpperCase().replace(/\s/g, "_").includes(x.type)
    );
    return res.type;
  }
  if (category.includes("Withdrawal")) return "WITHDRAWAL";
  if (category.includes("Cable TV")) return "CABLE_TV";
  if (category.includes("PHCN")) return "PHCN";

  return category.toUpperCase().replace(/\s/g, "_");
};

export const handleAccountType = (type) => {
  switch (type) {
    case AccountType.BUSINESS:
      return "Business Account";
    default:
      return "Individual Account";
  }
};

export const isTypeBills = (type) => {
  switch (type) {
    case "TRANSFER":
    case "AIRTIME":
    case "CABLE_TV":
    case "DATA":
    case "PHCN":
      return true;
    default:
      return false;
  }
};

export const modifiedFormRequest = (values, color) => {
  let subdomain = null;

  values.businessSecondaryEmails = [
    values.secondaryEmail,
    values.secondaryEmail2,
  ];

  if (color && color?.r && color?.g && color?.b && color?.a) {
    values.color = {
      primaryColor: `rgb(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
      secondaryColor: `rgb(${color.r}, ${color.g}, ${color.b}, ${0.1})`,
    };
  }

  if (values.customerSubdomain) {
    const parts = values.customerSubdomain.split(".");
    subdomain = parts[0];
  }

  delete values.customerSubdomain;
  delete values.email;
  delete values.secondaryEmail;
  delete values.secondaryEmail2;

  return {
    values: values,
    subdomain,
  };
};

export const Service = {
  services: "SERVICEFEE",
  commission: "COMMISSION",
};

const userRole = {
  ADMIN: "ADMIN",
  TEAM_LEAD: "TEAM_LEAD",
};

export const handleKycAccess = (role, department, disableKycCheck) => {
  if (disableKycCheck) {
    return false;
  }
  switch (role) {
    case userRole.ADMIN:
      return true;
    case userRole.TEAM_LEAD:
      return department === "BUSINESS" || department === "CUSTOMER_SUPPORT";
    default:
      return false;
  }
};

export const settlementWalletType = {
  SETTLEMENT: "settlement",
  USDSETTLEMENT: "usdsettlement",
};

export const CurrencyPricingType = {
  USD: "USD",
  NAIRA: "NAIRA",
};
export const formatFileSize = (bytes) => {
  if (bytes === 0) return "0 Bytes";

  const sizes = ["Bytes", "KB", "MB", "GB"];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const convertedSize = parseFloat((bytes / Math.pow(1024, i)).toFixed(2));
  return convertedSize + " " + sizes[i];
};

export const handleOptions = (options = [], label, value) =>
  options.map((list) => ({
    label: list[label],
    value: list[value],
    className: "capitalize",
  }));

export function formatGraphValue(value) {
  if (value >= 1000000000) {
    return (value / 1000000000).toFixed(0) + "B";
  }
  if (value >= 1000000) {
    return (value / 1000000).toFixed(0) + "M";
  }
  if (value >= 1000) {
    return (value / 1000).toFixed(0) + "K";
  } else {
    return value.toString();
  }
}

export const validateEmailAndUsername = async (_, value) => {
  // Regular expression for a valid email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Regular expression for a valid username
  const usernameRegex = /^[a-zA-Z0-9]{6,}$/;

  if (emailRegex.test(value) || usernameRegex.test(value)) {
    return Promise.resolve("success");
  } else {
    return Promise.reject(false);
  }
};

export const decodeBas64 = (base64String) => {
  if (!base64String) return;
  return new TextDecoder().decode(
    Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0))
  );
};

export function checkUsernameCharacter(str) {
  const regex = /^(?=.*[a-zA-Z])[a-zA-Z0-9]{7,}$/;
  if (regex.test(str)) {
    return Promise.resolve("success");
  } else {
    return Promise.reject(
      "Username must consist of at least 7 characters or alphanumeric but cannot consist of only numbers and space."
    );
  }
}

export function formatLabel(inputString) {
  if (
    typeof inputString !== "string" ||
    inputString === TransactionType.P2P ||
    inputString === TransactionType.PHCN
  ) {
    return inputString;
  }
  if (inputString === TransactionType.CABLE_TV) {
    return "Cable TV";
  }
  return inputString
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export function formatPhoneNumber(phoneNumber) {
  phoneNumber = phoneNumber.replace(/\s/g, "");

  phoneNumber = phoneNumber.replace(/^\+234/, "0");

  return phoneNumber;
}

export function findHighestValue(array, key) {
  if (array.length === 0) {
    return 0;
  }
  let highest = array[0][key];

  for (let i = 1; i < array.length; i++) {
    if (array[i][key] > highest) {
      highest = array[i][key];
    }
  }

  return highest;
}

export const getTspanGroups = (value, maxLineLength, x = 0, spaceY = 15) => {
  const words = value.split(" ");

  const assembleLines = words.reduce(
    (acc, word) => {
      if ((word + acc.currLine).length > maxLineLength && acc.currLine !== "") {
        return {
          lines: acc.lines.concat([acc.currLine]),
          currLine: word,
        };
      }
      return {
        ...acc,
        currLine: acc.currLine + " " + word,
      };
    },
    { lines: [], currLine: "" }
  );
  const allLines = assembleLines.lines.concat([assembleLines.currLine]);
  const lines = allLines.slice(0, 2);
  let children = [];
  let dy = 0;

  lines.forEach((lineText, i) => {
    children.push(
      <tspan x={x} dy={dy} key={i}>
        {lineText}
      </tspan>
    );
    dy += spaceY;
  });

  return children;
};

export const handleLogoAndLabel = (list) => {
  const cardList = list.flatMap((bank) =>
    bank.breakdown.map((card) => ({
      label: card.label,
      logo: card.logo,
    }))
  );

  const uniqueCardObj = cardList.reduce((acc, card) => {
    acc[card.label] = {
      ...card,
    };
    return acc;
  }, {});

  const cardKey = Object.keys(uniqueCardObj);
  const initalCardScheme = cardKey.reduce((obj, label) => {
    obj[label] = 0;
    return obj;
  }, {});

  return {
    uniqueCardObj,
    cardKey,
    initalCardScheme,
  };
};

export const joinSubscriptionPricing = (defaultPlan, pricingList) => {
  const mergedArray = [];

  for (let i = 0; i < pricingList.length; i++) {
    const plan = pricingList[i];
    const setting = defaultPlan.find((s) => s.category === plan.category);
    mergedArray.push({
      ...setting,
      discountPrice: plan.price,
      enable: plan.enable,
    });
  }
  return mergedArray;
};

export const CustomLabel = (data) => {
  const { x, value } = data;

  const modifedValue = formatLabel(value);

  return (
    <g
      transform={`translate(${x},0)`}
      style={{
        opacity: 1,
      }}
    >
      <line
        x1="0"
        x2="0"
        y1="0"
        y2="0"
        style={{
          stroke: "rgb(119, 119, 119)",
          strokeWidth: 1,
        }}
      ></line>
      <text
        textAnchor="middle"
        transform="translate(0,5) rotate(0)"
        dominantBaseline="text-before-edge"
        fontSize="14px"
        fontStyle="normal"
        fill="#475467"
        style={{
          textAlign: "center",
          lineHeight: 18,
          fontFamily: "Avenir",
          outlineWidth: "0px",
          outlineColor: "transparent",
          textTransform: "capitalize",
          fontWeight: 400,
        }}
      >
        {getTspanGroups(modifedValue, 10)}
      </text>
    </g>
  );
};
