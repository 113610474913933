import { lazy } from "react";
import Login from "../../modules/Auth/Login";

const loadModule = (link) => lazy(() => import(`../../modules/${link}`));
const loadLandingModule = (link) => lazy(() => import(`../../landing/${link}`));

export const authRoutes = [
  {
    path: "/splash",
    exact: true,
    Component: loadModule("Auth/Splash"),
  },
  {
    path: "/login",
    exact: true,
    Component: Login,
  },
  {
    path: "/signup",
    exact: false,
    Component: loadModule("Auth/Signup"),
  },

  {
    path: "/signup-type",
    exact: true,
    Component: loadModule("Auth/FinancialServiceType"),
  },
  {
    path: "/signup-docs",
    exact: false,
    Component: loadModule("Auth/DocumentUpload"),
  },
  {
    path: "/signup-subdomain",
    exact: false,
    Component: loadModule("Auth/Subdomain"),
  },
  {
    path: "/signup-contact-info",
    exact: false,
    Component: loadModule("Auth/ContactInformation"),
  },
  {
    path: ["/reset-password/:token"],
    exact: false,
    Component: loadModule("Auth/ResetPassword"),
  },
  {
    path: ["/verify-device"],
    exact: false,
    Component: loadModule("Auth/VerifyDevice"),
  },
  {
    path: ["/verify-email/:id", "/verify-email"],
    exact: false,
    Component: loadModule("Auth/VerifyEmail"),
  },
  {
    path: "/forgot-password",
    exact: false,
    Component: loadModule("Auth/ForgotPassword"),
  },
  {
    path: "/new-account",
    exact: false,
    Component: loadModule("Auth/NewAccount"),
  },
  {
    path: "/registration-success",
    exact: false,
    Component: loadModule("Auth/SuccessRegistration"),
  },
  {
    path: "/access",
    exact: true,
    Component: loadModule("Auth/Access"),
  },
];

export const mainRoutes = [
  {
    path: [
      "/transactions/list",
      "/loans/transaction/list",
      "/terminal/:terminalId/transaction",
    ],
    exact: true,
    Component: loadModule("Transactions/TransactionsListing"),
  },
  {
    path: ["/transactions/:id"],
    exact: true,
    Component: loadModule("Transactions/Transaction"),
  },
  {
    path: ["/transactions", "/transactions/:id"],
    exact: true,
    Component: loadModule("Transactions/Transactions"),
  },
  {
    path: ["/business/customers/list"],
    exact: true,
    Component: loadModule("Business/CustomerList"),
  },
  {
    path: ["/business/customers/history"],
    exact: true,
    Component: loadModule("Business/CustomersHistory"),
  },
  {
    path: ["/business/customers/:id/:phone/profile"],
    exact: true,
    Component: loadModule("Business/Customer"),
  },
  {
    path: [
      "/business/customers/:phone/:id/transactions",
      "/cashpoint/:id/transactions",
    ],
    exact: true,
    Component: loadModule("Business/CustomerTransactions"),
  },
  {
    path: [
      "/business/customers/:id/wallet-transactions",
      "/cashpoint/:id/wallet-transactions",
    ],
    exact: true,
    Component: loadModule("Business/CustomerWalletTransactions"),
  },
  {
    path: ["/business/terminals/list"],
    exact: true,
    Component: loadModule("Business/TerminalListing"),
  },
  {
    path: ["/business/terminals/:id"],
    exact: true,
    Component: loadModule("Business/Terminal"),
  },
  {
    path: ["/business/aggregators/:id/customers"],
    exact: true,
    Component: loadModule("Business/AggregatorCustomerList"),
  },
  {
    path: ["/business/aggregators/:id/settings"],
    exact: true,
    Component: loadModule("Business/SalesCommission"),
  },
  {
    path: ["/business/aggregators/:id"],
    exact: true,
    Component: loadModule("Business/Aggregator"),
  },
  {
    path: ["/business/aggregators/:id/subAggregators"],
    exact: true,
    Component: loadModule("Business/SubAggregator"),
  },
  {
    path: ["/business/aggregators/:id/list"],
    exact: true,
    Component: loadModule("Business/SubAggregatorsListPage"),
  },
  {
    path: ["/business/aggregators"],
    exact: true,
    Component: loadModule("Business/SalesListing"),
  },
  {
    path: ["/business"],
    exact: true,
    Component: loadModule("Business/Business"),
  },
  {
    path: ["/business/create-aggregator"],
    exact: true,
    Component: loadModule("Business/Business"),
  },
  {
    path: ["/terminal"],
    exact: true,
    Component: loadModule("TerminalManagement/TerminalManagement"),
  },
  {
    path: ["/terminal/list"],
    exact: true,
    Component: loadModule("TerminalManagement/TerminalList"),
  },
  {
    path: ["/terminal/:id"],
    exact: true,
    Component: loadModule("TerminalManagement/TerminalInfo"),
  },
  {
    path: "/consumer/list",
    exact: true,
    Component: loadModule("Consumer/ConsumerListing"),
  },
  {
    path: "/consumer",
    exact: true,
    Component: loadModule("Consumer/Consumer"),
  },
  {
    path: "/consumer/:id/:phone",
    exact: true,
    Component: loadModule("Consumer/ConsumerProfile"),
  },

  {
    path: ["/accounts/list"],
    exact: true,
    Component: loadModule("Accounts/AccountsListing"),
  },
  {
    path: ["/accounts/:account"],
    exact: true,
    Component: loadModule("Accounts/Account"),
  },
  {
    path: ["/accounts/:account/transactions"],
    exact: true,
    Component: loadModule("Accounts/TransactionsListing"),
  },
  {
    path: ["/accounts"],
    exact: true,
    Component: loadModule("Accounts/Accounts"),
  },
  {
    path: ["/loans"],
    exact: true,
    Component: loadModule("Loans/Loans"),
  },
  {
    path: ["/loans/create-offer"],
    exact: true,
    Component: loadModule("Loans/CreateLoanProduct"),
  },
  {
    path: "/loans/requests",
    exact: true,
    Component: loadModule("Loans/LoanRequestList"),
  },
  {
    path: "/loans/product",
    exact: true,
    Component: loadModule("Loans/LoanProduct"),
  },
  {
    path: "/loans/request-overview",
    exact: true,
    Component: loadModule("Loans/LoanRequest"),
  },

  {
    path: "/loans/running-loans",
    exact: true,
    Component: loadModule("Loans/MFBRunningLoans"),
  },
  {
    path: "/loans/transaction",
    exact: true,
    Component: loadModule("Loans/LoanWallet"),
  },
  {
    path: "/loans/repayment",
    exact: true,
    Component: loadModule("Loans/LoanRepayment"),
  },
  {
    path: ["/loans/repayment/list", "/loans/disburse/list"],
    exact: true,
    Component: loadModule("Loans/RepaymentList"),
  },
  {
    path: ["/loans/breakdown/:id"],
    exact: true,
    Component: loadModule("Loans/RepaymentBreakdown"),
  },
  {
    path: ["/loans/requests/:id"],
    exact: true,
    Component: loadModule("Loans/CustomerLoanRequest"),
  },
  {
    path: ["/loans/requests/:id/wallet"],
    exact: true,
    Component: loadModule("Loans/CustomerWalletAccount"),
  },
  {
    path: ["/loans/requests/:id/bank"],
    exact: true,
    Component: loadModule("Loans/CustomerBankAccount"),
  },
  {
    path: ["/loans/requests/:id/credit"],
    exact: true,
    Component: loadModule("Loans/CustomerCreditStore"),
  },
  {
    path: ["/loans/requests/:id/kyc"],
    exact: true,
    Component: loadModule("Loans/Customer_KYC"),
  },
  {
    path: ["/support/tickets/:id/:userId/:isCreatedByBanker"],
    exact: true,
    Component: loadModule("Support/Ticket"),
  },
  {
    path: ["/support/tickets"],
    exact: true,
    Component: loadModule("Support/Tickets"),
  },
  {
    path: ["/support"],
    exact: true,
    Component: loadModule("Support/Support"),
  },
  {
    path: ["/support/issue/:category"],
    exact: true,
    Component: loadModule("Support/IssueTransaction"),
  },
  {
    path: ["/settings"],
    exact: true,
    Component: loadModule("Settings/Settings"),
  },
  {
    path: ["/settings/network-status"],
    exact: true,
    Component: loadModule("Settings/NetworkManagement"),
  },
  {
    path: ["/settlement"],
    exact: true,
    Component: loadModule("Settlement/Settlements"),
  },
  {
    path: ["/settlement/list"],
    exact: true,
    Component: loadModule("Settlement/Table"),
  },

  {
    path: ["/settlement/wallet-history"],
    exact: true,
    Component: loadModule("Settlement/SettlementWalletHistory"),
  },

  {
    path: ["/settlement/:id"],
    exact: true,
    Component: loadModule("Settlement/Settlement"),
  },
  {
    path: ["/disbursement"],
    exact: true,
    Component: loadModule("Disbursements/Disbursements"),
  },
  {
    path: ["/disbursement/list"],
    exact: true,
    Component: loadModule("Disbursements/MainTable"),
  },
  {
    path: ["/disbursement/wallet-history", "/loans/wallet-history"],
    exact: true,
    Component: loadModule("Disbursements/DisbursementWalletHistory"),
  },

  {
    path: ["/disbursement/:id"],
    exact: true,
    Component: loadModule("Disbursements/Disbursement"),
  },
  {
    path: ["/revenue"],
    exact: true,
    Component: loadModule("Revenue/Revenue"),
  },
  {
    path: ["/revenue/wallet-history"],
    exact: true,
    Component: loadModule("Revenue/RevenueWalletHistory"),
  },
  {
    path: "/engagement",
    exact: true,
    Component: loadModule("CustomerEngagement/CustomerEngagement"),
  },

  {
    path: "/engagement/create",
    exact: true,
    Component: loadModule("CustomerEngagement/CreateNotification"),
  },

  {
    path: "/engagement/list",
    exact: true,
    Component: loadModule("CustomerEngagement/NotificationList"),
  },
  {
    path: "/analytics",
    exact: true,
    Component: loadModule("Analytics/Analytics"),
  },
  {
    path: "/account-form",
    exact: true,
    Component: loadModule("AccountForm/AccountFormOverview"),
  },
  {
    path: ["/account-form/create"],
    exact: true,
    Component: loadModule("AccountForm/AccountForm"),
  },
  {
    path: ["/account-form/:id"],
    exact: true,
    Component: loadModule("AccountForm/components/FormResponse"),
  },
  {
    path: "/account-form/:id/list",
    exact: true,
    Component: loadModule("AccountForm/components/FormResponseList"),
  },
  {
    path: "/account-form/response/:id",
    exact: true,
    Component: loadModule("AccountForm/components/IndividualResponse"),
  },
  {
    path: "/cashpoint",
    exact: true,
    Component: loadModule("Cashpoint/Cashpoint"),
  },
  {
    path: "/cashpoint/list",
    exact: true,
    Component: loadModule("Cashpoint/TableList"),
  },
  {
    path: "/cashpoint/:phoneNumber",
    exact: true,
    Component: loadModule("Cashpoint/CustomerCashpoint"),
  },
  {
    path: "/cashpoint/:phoneNumber/:id",
    exact: true,
    Component: loadModule("Cashpoint/CashpointDetail"),
  },
  {
    path: "/settings/transaction-control",
    exact: true,
    Component: loadModule("Settings/TransactionControl"),
  },
  {
    path: ["/subscription/feature-plan"],
    exact: true,
    Component: loadModule("Subscription/FeatureSubscription"),
  },
  {
    path: ["/subscription"],
    exact: true,
    Component: loadModule("Subscription/Subscription"),
  },
  {
    path: ["/home", "/dashboard"],
    exact: true,
    Component: loadModule("Dashboard/Dashboard"),
  },
  {
    path: ["/home", "/dashboard"],
    exact: true,
    Component: loadModule("Dashboard/Dashboard"),
  },
];

export const landingRoutes = [
  {
    path: "/products/terminal",
    exact: true,
    Component: loadLandingModule("Terminal"),
  },
  {
    path: "/products/agent-banking",
    exact: true,
    Component: loadLandingModule("AgentBanking"),
  },
  {
    path: "/products/lending",
    exact: true,
    Component: loadLandingModule("Lending"),
  },
  {
    path: "/products/virtual-account",
    exact: true,
    Component: loadLandingModule("VirtualAccounts"),
  },
  {
    path: "/terms-and-conditions",
    exact: true,
    Component: loadLandingModule("TermsAndConditions"),
  },
  {
    path: "/policy",
    exact: true,
    Component: loadLandingModule("Policy"),
  },
  {
    path: "/",
    exact: true,
    Component: loadLandingModule("Home"),
  },
];
