import disburseTypes from "./types";

const initialState = {
  disburseList: {
    list: [],
    page: "",
    total: "",
    pageSize: "",
  },
};

const disbursementReducer = (state = initialState, action) => {
  switch (action.type) {
    case disburseTypes.SET_DISBURSEMENT:
      return {
        ...state,
        disburseList: {
          ...state.disburseList,
          list: action.payload.list,
          page: action.payload.page,
          total: action.payload.total,
          pageSize: Math.ceil(action.payload.total / 10),
        },
      };

    default:
      return state;
  }
};

export default disbursementReducer;
