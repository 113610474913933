import { store } from "../../../store";
import HttpRequests from "../../../common/utils/HttpRequests";
import url from "../../../common/utils/url";
import { types } from "../../Business/store/types";

const { dispatch } = store;
const Request = new HttpRequests();

export const getSubscriptionPricing = async (type) => {
  try {
    const response = await Request.get(`/${url.subscription}/${type}/plan`);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Something went wrong",
    });
  }
};

export const getPremiumSubscription = async (query) => {
  try {
    const response = await Request.get(`/${url.subscription}`, query);
    dispatch({ type: types.PREMIUM_BY_TYPE, payload: response.data });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message:
        error.response?.data?.message || "Failed to fetch subscription list",
    });
  }
};

export const handleFreeTrailByType = async (data) => {
  try {
    const response = await Request.post(
      `/${url.subscription}/free-trial`,
      null,
      data
    );

    dispatch({ type: types.PREMIUM_BY_TYPE, payload: [response.data] });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Something went wrong",
    });
  }
};

export const handlePayment = async (data) => {
  try {
    const response = await Request.post(
      `/${url.subscription}/subscribe`,
      null,
      data
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Payment Failed",
    });
  }
};

export const UnSubscribePaymentPlan = async (id, payload) => {
  try {
    const response = await Request.put(
      `/${url.subscription}/${id}/unsubscribe`,
      null,
      payload
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed to UnSubsribe",
    });
  }
};

export const toogleRenwal = async (id, payload) => {
  try {
    const response = await Request.put(
      `/${url.subscription}/${id}/status`,
      null,
      payload
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed",
    });
  }
};
