import types from "./types";

const initialState = {
  authenticated: false,
  authLevel: "",
  user: {
    id: "",
    email: "",
    firstName: "",
    lastName: "",
    approval: "",
    pages: [],
    disableKycCheck: false,
  },
  userCategory: "",
  formInput: {},
  validPhoneNumber: false,
  otpExpiration: 1610374821180,
  newUser: false,
  stateList: [],
  lgaList: [],
  browserRequestID: "",
  secretKey: "",
  currency: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_USER:
      return { ...state, authenticated: true };

    case types.LOGOUT_USER:
      return {
        ...state,
        authenticated: false,
        user: {},
      };

    case types.SET_USER_TYPE:
      return { ...state, authLevel: action.payload };

    case types.UPDATE_USER:
      return { ...state, user: { ...state.user, ...action.payload } };

    case types.SET_USER:
      return { ...state, user: action.payload };

    case types.SET_CATEGORY:
      return { ...state, userCategory: action.payload };

    case types.SET_STATE:
      return { ...state, stateList: action.payload };

    case types.SET_LGA:
      return { ...state, lgaList: action.payload };

    case types.SET_NEW_USER:
      return { ...state, newUser: action.payload };

    case types.FORM_INPUT:
      return { ...state, formInput: action.payload };

    case types.SET_VALIDATE_PHONE:
      return { ...state, validPhoneNumber: action.payload };

    case types.SET_EXPIRATION:
      return { ...state, otpExpiration: action.payload };

    case types.SET_BROWSER_REQUEST_ID:
      return { ...state, browserRequestID: action.payload };

    case types.SET_SECRET_KEY:
      return { ...state, secretKey: action.payload.secretKey };

    case types.UPDATE_CURRENCY:
      return { ...state, currency: action.payload };

    default:
      return state;
  }
};

export default authReducer;
