import React from "react";
import Icon from "@ant-design/icons";
import magnet from "../../../assets/svg/magnet.svg";

const MagnetSvg = () => {
  return <img src={magnet} alt="magnet" />;
};

const Magnet = (props) => <Icon component={MagnetSvg} {...props} />;

export default Magnet;
