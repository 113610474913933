const types = {
  LOGIN_USER: "LOGIN USER",
  LOGOUT_USER: "LOGOUT USER",
  SET_USER_TYPE: "SET USER TYPE",
  PARTNER_APPROVAL: "PARTNER APPROVAL",
  SET_USER: "SET USER",
  SET_CATEGORY: "SET CATEGORY",
  FORM_INPUT: "FORM INPUT",
  SET_NEW_USER: "NEW USER",
  SET_LGA: "SET LGA",
  SET_STATE: "SET STATE",
  SET_EXPIRATION: "OTP EXPIRATION TIME",
  SET_VALIDATE_PHONE: "SET VALID PHONE NUMBER",
  SET_BROWSER_REQUEST_ID: "SET BROWSER REQUEST ID",
  SET_SECRET_KEY: "SET SECRET KEY",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_CURRENCY: "UPDATE_CURRENCY",
};

export default types;
