import supportTypes from "./types";

const initialState = {
  categoriesList: [],
  ticketCategories: [],
  showIssueModal: false,
  showIssueType: false,
  issueInfo: false,
};

const supportReducer = (state = initialState, action) => {
  switch (action.type) {
    case supportTypes.SET_SETTLEMENT:
      return {
        ...state,
        settlementList: {
          ...state.settlementList,
          list: action.payload.list,
          page: action.payload.page,
          total: action.payload.total,
          pageSize: Math.ceil(action.payload.total / 10),
        },
      };
    case supportTypes.TICKET_CATEGORIES:
      return { ...state, ticketCategories: action.payload };
    case supportTypes.SHOW_ISSUE_MODAL:
      return { ...state, showIssueModal: true, issueInfo: action.payload };

    case supportTypes.HIDE_ISSUE_MODAL:
      return { ...state, showIssueModal: false };

    case supportTypes.CLOSE_ISSUE_MODAL:
      return { ...state, showIssueModal: false, showIssueType: true };

    case supportTypes.CLOSE_TYPE_SHOW_ISSUE_MODAL:
      return { ...state, showIssueModal: true, showIssueType: false };

    case supportTypes.HIDE_ISSUE_TYPE:
      return { ...state, showIssueType: false };
    default:
      return state;
  }
};

export default supportReducer;
