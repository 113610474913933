import React from "react";
import AuthButton from "../Buttons/AuthButton";
import ModalShell from "./ModalShell";
import exit from "../../../assets/svg/exit.svg";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../modules/Auth/store/actions";

const LogoutModal = ({ show, onCancel }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  function logout() {
    dispatch(logoutUser(history));
  }

  return (
    <ModalShell show={show} cancel={onCancel}>
      <div className="flex flex-col items-center">
        <img className="my-5" src={exit} alt="in-progress" />

        <p className="text-2xl font-bold mb-2">
          Are you Sure you want to logout?
        </p>

        <div className="flex w-full justify-between">
          <AuthButton htmlType="submit" className="w-full" onClick={logout}>
            Yes, Logout
          </AuthButton>
        </div>
      </div>
    </ModalShell>
  );
};
export default LogoutModal;
