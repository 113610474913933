import React from "react";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import url from "../../utils/url";

const ImageAvatar = ({
  profileImageId,
  src,
  size,
  className,
  shape,
  style,
  maxStyle,
}) => {
  return profileImageId && profileImageId !== undefined ? (
    <Avatar
      style={style}
      className={className}
      shape={shape || "circle"}
      size={size || 120}
      src={`${url.base}/media/${profileImageId}`}
    />
  ) : src ? (
    <Avatar
      style={style}
      className={className}
      shape={shape || "circle"}
      size={size || 120}
      src={src}
    />
  ) : (
    <Avatar
      style={style}
      className={`${className} flex items-center bg-gray-100 justify-center`}
      shape={shape || "circle"}
      size={size || 120}
      icon={<UserOutlined className="text-blue-200" />}
    />
  );
};

export default ImageAvatar;
