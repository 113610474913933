import React from "react";
import { Input } from "antd";

/**
 * Form Input component
 * @param {String} label
 * @param {String} type i.e "text","email"
 * @param {String} placeholder
 * @param {Boolean} hasValue i.e indicates in
 * @param {Function} onChange. {args:value}
 * @returns react component
 */

const CustomPasswordInput = ({
  id,
  name,
  label,
  type,
  placeholder,
  hasValue,
  onChange,
  value,
  bottomTag,
}) => {
  return (
    <div>
      <Input.Password
        name={name}
        id={id}
        value={value ?? value}
        type={type ?? type}
        className={`auth-input ${hasValue ? "filled" : ""}`}
        placeholder={placeholder}
        onChange={onChange ?? onChange}
      />
      {bottomTag ?? bottomTag}
      <label className={`auth-label ${hasValue ? "filled" : ""}`}>
        {label}
      </label>
    </div>
  );
};

export default CustomPasswordInput;
