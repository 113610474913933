import React, { useCallback, useEffect, useRef, useState } from "react";
import { Menu, Layout, Button, Spin } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import {
  FormOutlined,
  SettingOutlined,
  TransactionOutlined,
} from "@ant-design/icons";
import Home from "./icons/Home";
import useWindowSize from "../utils/windowSize";
import { FaBars, FaTimes } from "react-icons/fa";
import Order from "./icons/Order";
import Customer from "./icons/Customers";
import Analysis from "./icons/Analysis";
import Handshake from "./icons/Handshake";
import Person from "./icons/Person";
import url from "../utils/url";
import { useSelector } from "react-redux";
import Revenue from "./icons/Revenue";
import Magnet from "./icons/Magnet";
import Loan from "./icons/Loans";
import Chart from "./icons/Chart";
import crown from "../../assets/crown.png";
import { usePremiumType } from "../utils/Hooks";
import { PremiumFeature } from "../utils/helper";

const menu = [
  {
    path: ["business", "terminals", "customers", "consumer"],
    key: "customers",
  },
  { path: ["transactions"], key: "transactions" },
  { path: ["terminal"], key: "terminal" },
  { path: ["disbursement"], key: "disbursement" },
  { path: ["settlement"], key: "settlement" },
  { path: ["cashpoint"], key: "cashpoint" },

  { path: ["revenue"], key: "revenue" },
  { path: ["support"], key: "support" },
  {
    path: [
      "credit",
      "request",
      "repayment",
      "running-loans",
      "product",
      "loans",
      "loans/wallet-history",
    ],
    key: "lending",
  },
  // { path: ["settings"], key: "settings" },
  { path: ["analytics"], key: "analytics" },
  { path: ["account-form"], key: "customersTemp" },
  { path: ["engagement"], key: "engagement" },
];

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = () => {
  const width = useWindowSize().width;
  const [open, setOpen] = useState(false);
  const [selectedKey, setKey] = useState();
  const [openedKeys, setOpenedKeys] = useState([]);
  const location = useLocation();
  const ref = useRef();
  const user = useSelector((s) => s.auth.user);
  const role = user?.role === "ADMIN";
  const logoImage = user?.profileImageId;
  const pages = user.pages || [];
  const { isLoading, premium } = usePremiumType({ type: null });
  const ctaButton =
    Object.values(premium[PremiumFeature.TMS]).length > 1 ||
    Object.values(premium[PremiumFeature.ANALYTICS]).length > 1
      ? {
          actionText: "View Subscription",
          plainText:
            "Explore our premium features and make the most out of your subscription today!",
        }
      : {
          actionText: "Try now",
          plainText: "Unlock exclusive benefits with our premium subscription.",
        };

  const setSelectedMenuByLocation = useCallback(
    (menu) => {
      let path = location.pathname;
      let key = menu?.filter((item) =>
        item.path?.some((itemPath) => path.includes(itemPath))
      )[0]?.key;

      if (key) {
        setOpenedKeys([key]);
        return setKey(key);
      }
      setKey();
    },
    [location.pathname]
  );

  function toggle() {
    setOpen(!open);
  }

  const close = useCallback((e) => {
    if (ref.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  }, []);

  useEffect(() => {
    setSelectedMenuByLocation(menu);
  }, [setSelectedMenuByLocation]);

  useEffect(() => {
    if (width <= 1024) {
      document.addEventListener("mousedown", close);
    }
    return () => {
      document.removeEventListener("mousedown", close);
    };
  }, [width, close]);

  return (
    <React.Fragment>
      <Sider
        theme="light"
        className={`sidebar border-r border-gray-200 border-opacity-50 border-solid py-2 ${
          open ? "open" : ""
        }`}
      >
        <Button
          className="mikro-blue-2 fixed w-32 h-32 border-0 xl:hidden shadow-none flex items-center 
            justify-center bg-white "
          type="primary"
          size="large"
          icon={open ? <FaTimes /> : <FaBars />}
          onClick={toggle}
        />
        <div className="pb-3" ref={ref}>
          <div className="logo w-full max-h-24 pb-10 p-3 flex justify-center">
            {logoImage && (
              <img
                className="max-w-full max-h-full object-contain"
                src={`${url.base}/media/${logoImage}`}
                alt="logoImage"
              />
            )}
          </div>
          <Menu
            className="border-r-0 menu"
            theme="light"
            mode="inline"
            defaultSelectedKeys={["home"]}
            selectedKeys={selectedKey}
            openKeys={openedKeys}
            onOpenChange={(e) => setOpenedKeys(e)}
          >
            {pages?.includes("HOME") && (
              <Menu.Item key="home" icon={<Home />}>
                <NavLink
                  exact
                  to="/home"
                  activeClassName="active"
                  onClick={() => setOpen(false)}
                >
                  Home
                </NavLink>
              </Menu.Item>
            )}

            {pages?.includes("TRANSACTIONS") && (
              <Menu.Item key="transactions" icon={<Order />}>
                <NavLink
                  to="/transactions"
                  activeClassName="active"
                  onClick={() => setOpen(false)}
                >
                  Transactions
                </NavLink>
              </Menu.Item>
            )}

            <SubMenu key="customers" icon={<Customer />} title={"Customers"}>
              {pages?.includes("BUSINESS") && (
                <Menu.Item key="business">
                  <NavLink
                    to="/business"
                    activeClassName="active"
                    onClick={() => setOpen(false)}
                  >
                    Business
                  </NavLink>
                </Menu.Item>
              )}

              {pages?.includes("CONSUMER") && (
                <Menu.Item key="consumer">
                  <NavLink
                    to="/consumer"
                    activeClassName="active"
                    onClick={() => setOpen(false)}
                  >
                    Consumer
                  </NavLink>
                </Menu.Item>
              )}
            </SubMenu>
            {pages?.includes("CASHPOINT") && (
              <Menu.Item key="cashpoint" icon={<TransactionOutlined />}>
                <NavLink
                  to="/cashpoint"
                  activeClassName="active"
                  onClick={() => setOpen(false)}
                >
                  Cashpoint
                </NavLink>
              </Menu.Item>
            )}

            {/* {pages?.includes("ACCOUNT") && (
              <Menu.Item key="accounts" icon={<Accounts />}>
                <NavLink
                  exact
                  to="/accounts"
                  activeClassName="active"
                  onClick={() => setOpen(false)}
                >
                  Accounts
                </NavLink>
              </Menu.Item>
            )} */}

            {pages?.includes("DISBURSEMENT") && (
              <Menu.Item key="disbursement" icon={<Analysis />}>
                <NavLink
                  to="/disbursement"
                  activeClassName="active"
                  onClick={() => setOpen(false)}
                >
                  Disbursement
                </NavLink>
              </Menu.Item>
            )}

            {pages?.includes("SETTLEMENT") && (
              <Menu.Item key="settlement" icon={<Handshake />}>
                <NavLink
                  to="/settlement"
                  activeClassName="active"
                  onClick={() => setOpen(false)}
                >
                  Settlement
                </NavLink>
              </Menu.Item>
            )}

            {pages?.includes("LOAN") && (
              <SubMenu key="lending" icon={<Loan />} title={"Lending"}>
                <Menu.Item key="loans">
                  <NavLink
                    to="/loans"
                    activeClassName="active"
                    exact
                    onClick={() => setOpen(false)}
                  >
                    Loans Overview
                  </NavLink>
                </Menu.Item>

                <Menu.Item key="loan-product">
                  <NavLink
                    to="/loans/product"
                    activeClassName="active"
                    onClick={() => setOpen(false)}
                  >
                    Loan Offers
                  </NavLink>
                </Menu.Item>

                <Menu.Item key="request">
                  <NavLink
                    to="/loans/request-overview"
                    isActive={(_, location) => {
                      return location.pathname.includes("request");
                    }}
                    activeClassName="active"
                    onClick={() => setOpen(false)}
                  >
                    Loan Requests
                  </NavLink>
                </Menu.Item>

                <Menu.Item key="repayment">
                  <NavLink
                    to="/loans/repayment"
                    activeClassName="active"
                    onClick={() => setOpen(false)}
                  >
                    Loan Repayment
                  </NavLink>
                </Menu.Item>

                <Menu.Item key="credit">
                  <NavLink
                    exact
                    to="/loans/transaction"
                    activeClassName="active"
                    isActive={(_, location) => {
                      return (
                        location.pathname.includes("loans/transaction") ||
                        location.pathname === "loans/wallet-history"
                      );
                    }}
                    onClick={() => setOpen(false)}
                  >
                    Loan Account
                  </NavLink>
                </Menu.Item>
              </SubMenu>
            )}

            {/*pages?.includes("TERMINAL-MANAGEMENT") && (*/}
            <Menu.Item key="terminal" icon={<Customer />}>
              <NavLink
                to="/terminal"
                activeClassName="active"
                className="flex items-center gap-3"
                onClick={() => setOpen(false)}
              >
                <span>Terminal Mgt</span>
                <img className="w-4 h-4" src={crown} alt="crown" />
              </NavLink>
            </Menu.Item>

            {pages?.includes("ACCOUNT-FORM") && (
              <Menu.Item key="customersTemp" icon={<FormOutlined />}>
                <NavLink
                  to="/account-form"
                  activeClassName="active"
                  onClick={() => setOpen(false)}
                >
                  Account Opening
                </NavLink>
              </Menu.Item>
            )}

            {pages?.includes("ENGAGEMENT") && (
              <Menu.Item key="engagement" icon={<Magnet />}>
                <NavLink
                  to="/engagement"
                  activeClassName="active"
                  onClick={() => setOpen(false)}
                >
                  Engagement
                </NavLink>
              </Menu.Item>
            )}

            {pages?.includes("REVENUE") && (
              <Menu.Item key="revenue" icon={<Revenue />}>
                <NavLink
                  to="/revenue"
                  activeClassName="active"
                  onClick={() => setOpen(false)}
                >
                  Revenue
                </NavLink>
              </Menu.Item>
            )}
            {pages?.includes("SUPPORT") && (
              <Menu.Item key="support" icon={<Person />}>
                <NavLink
                  to="/support"
                  activeClassName="active"
                  onClick={() => setOpen(false)}
                >
                  Support
                </NavLink>
              </Menu.Item>
            )}

            {/*pages?.includes("ANALYTICS") && (*/}
            <Menu.Item key="analytics" icon={<Chart />}>
              <NavLink
                className="flex items-center gap-3"
                onClick={() => setOpen(false)}
                to="/analytics"
                exact
                activeClassName="active"
              >
                <span>Analytics</span>
                <img className="w-4 h-4" src={crown} alt="analytics-crown" />
              </NavLink>
            </Menu.Item>

            {pages?.includes("SETTINGS") && (
              <Menu.SubMenu
                key="settings"
                title="Settings"
                icon={<SettingOutlined />}
              >
                <Menu.Item key="settings">
                  <NavLink
                    to="/settings"
                    activeClassName="active"
                    onClick={() => setOpen(false)}
                  >
                    Settings
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="network-status">
                  <NavLink
                    to="/settings/network-status"
                    activeClassName="active"
                    onClick={() => setOpen(false)}
                  >
                    Network Monitoring
                  </NavLink>
                </Menu.Item>
                {role && (
                  <Menu.Item key="transaction-control">
                    <NavLink
                      to="/settings/transaction-control"
                      activeClassName="active"
                      onClick={() => setOpen(false)}
                    >
                      Transaction Control
                    </NavLink>
                  </Menu.Item>
                )}
              </Menu.SubMenu>
            )}
          </Menu>
          {role && (
            <Spin spinning={isLoading}>
              <div className="m-4 bg-violet-50 text-gray-500 rounded px-4 py-5">
                <h3 className="text-base font-bold"> Subscription</h3>
                <p className="pt-1">{ctaButton.plainText}</p>
                <NavLink
                  to="/subscription"
                  activeClassName="active"
                  onClick={() => setOpen(false)}
                >
                  <p className="font-bold text-sm mt-4 !text-[#7F7DD3]">
                    {ctaButton.actionText}
                  </p>
                </NavLink>
              </div>
            </Spin>
          )}
        </div>
      </Sider>
    </React.Fragment>
  );
};

export default Sidebar;
