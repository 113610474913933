import { combineReducers } from "redux";
import authReducer from "../modules/Auth/store/reducer";
import analyticsReducer from "../modules/Analytics/store/reducer";
import settingsReducer from "../modules/Settings/store/reducer";
import businessReducer from "../modules/Business/store/reducer";
import dashboardReducer from "../modules/Dashboard/store/reducer";
import supportReducer from "../modules/Support/store/reducer";
import transactionReducer from "../modules/Transactions/store/reducer";
import settlementReducer from "../modules/Settlement/store/reducer";
import disbursementReducer from "../modules/Disbursements/store/reducer";
import loanReducer from "../modules/Loans/store/reducer";
import accountFormReducer from "../modules/AccountForm/store/reducer";
import payoutReducer from "../modules/Cashpoint/store/reducer";
import terminalReducer from "../modules/TerminalManagement/store/reducer";

const Reducer = combineReducers({
  auth: authReducer,
  analytics: analyticsReducer,
  business: businessReducer,
  dashboard: dashboardReducer,
  settings: settingsReducer,
  settlement: settlementReducer,
  disbursement: disbursementReducer,
  support: supportReducer,
  transaction: transactionReducer,
  loan: loanReducer,
  accountForm: accountFormReducer,
  payout: payoutReducer,
  terminal: terminalReducer,
});

export default Reducer;
