import settingsActionTypes from "./types";

const initialState = {
  pageTab: "business information",
  selectedMember: "",
  selectedMemberId: "",
  showMemberEditForm: false,
  showMemberActivatePrompt: false,
  showMemberDeactivatePrompt: false,
  showMemberDeletePrompt: false,
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case settingsActionTypes.SET_TAB:
      return { ...state, pageTab: action.payload };

    case settingsActionTypes.SET_SELECTED_MEMBER:
      return { ...state, selectedMember: action.payload };

    case settingsActionTypes.SET_SELECTED_MEMBER_ID:
      return { ...state, selectedMemberId: action.payload };

    case settingsActionTypes.TOGGLE_EDIT_MEMBER:
      return { ...state, showMemberEditForm: action.payload };

    case settingsActionTypes.TOGGLE_ACTIVATE_MEMBER:
      return { ...state, showMemberActivatePrompt: action.payload };

    case settingsActionTypes.TOGGLE_DEACTIVATE_MEMBER:
      return { ...state, showMemberDeactivatePrompt: action.payload };

    case settingsActionTypes.TOGGLE_DELETE_MEMBER:
      return { ...state, showMemberDeletePrompt: action.payload };

    default:
      return state;
  }
};

export default settingsReducer;
