import { types } from "./types";

const initialState = {
  pageTab: "form",
};

const accountFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TAB:
      return { ...state, pageTab: action.payload };

    default:
      return state;
  }
};

export default accountFormReducer;
