import React from "react";
import { Input, Spin } from "antd";

/**
 * Form Input component
 * @param {String} label
 * @param {String} type i.e "text","email"
 * @param {String} placeholder
 * @param {Boolean} hasValue i.e indicates in
 * @param {Function} onChange. {args:value}
 * @returns react component
 * @param {String} span
 **/

const CustomInput = ({
  label,
  id,
  name,
  type,
  placeholder,
  hasValue,
  onChange,
  value,
  loading,
  bottomTag,
  span,
  onBlur,
  onFocus,
  disabled,
  max,
  className,
}) => {
  return (
    <div>
      <Input
        value={value ?? value}
        type={type ?? type}
        id={id}
        name={name}
        className={`auth-input ${className} ${hasValue ? "filled" : ""}`}
        placeholder={placeholder}
        onChange={onChange ?? onChange}
        loading={loading}
        onBlur={onBlur}
        maxLength={max}
        onFocus={onFocus}
        disabled={disabled}
      />
      {bottomTag ?? bottomTag}
      <label className={`auth-label ${hasValue ? "filled" : ""}`}>
        {label}
      </label>
      {loading && <Spin className="absolute right-2 top-5" size="small" />}
      <span className="text-blue-400">{span}</span>
    </div>
  );
};

export default CustomInput;
