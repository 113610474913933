const supportTypes = {
  SET_TICKETS: "SET TICKETS",
  TICKET_CATEGORIES: "TICKET CATEGORIES",
  SHOW_ISSUE_MODAL: "SHOW ISSUE MODAL",
  HIDE_ISSUE_MODAL: "HIDE ISSUE MODAL",
  CLOSE_ISSUE_MODAL: "CLOSE ISSUE MODAL",
  HIDE_ISSUE_TYPE: "HIDE ISSUE TYPE",
  CLOSE_TYPE_SHOW_ISSUE_MODAL: "CLOSE TYPE SHOW ISSUE ",
};

export default supportTypes;
