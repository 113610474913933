import settleTypes from "./types";

const initialState = {
  settlementList: {
    list: [],
    page: "",
    total: "",
    pageSize: "",
  },
};

const settlementReducer = (state = initialState, action) => {
  switch (action.type) {
    case settleTypes.SET_SETTLEMENT:
      return {
        ...state,
        settlementList: {
          ...state.settlementList,
          list: action.payload.list,
          page: action.payload.page,
          total: action.payload.total,
          pageSize: Math.ceil(action.payload.total / 10),
        },
      };

    default:
      return state;
  }
};

export default settlementReducer;
