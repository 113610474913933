import transactionStoreTypes from "./types";

const initialState = {
  transactionTypesList: [],
  transactionStatusList: [],
};

const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case transactionStoreTypes.SET_TRANSACTION_TYPES:
      return { ...state, transactionTypesList: action.payload };

    case transactionStoreTypes.SET_TRANSACTION_STATUS:
      return { ...state, transactionStatusList: action.payload };

    default:
      return state;
  }
};

export default transactionReducer;
