import React, { useEffect, useState, useCallback } from "react";
import { Layout } from "antd";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import AccountInfoModal from "../components/Modal/AccountInfoModal";
import ChangePasswordModal from "../components/Modal/ChangePasswordModal";
import LogoutModal from "../components/Modal/LogoutModal";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

const AppShell = ({ children }) => {
  const [accountInfoModal, setAccountInfoModal] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [showLogoutModal, setLogoutModal] = useState(false);
  const staffProfile = useSelector((state) => state.auth.user);
  const isAuth = useSelector((s) => s.auth.authenticated);
  const { pages } = useSelector((s) => s.auth.user);
  const history = useHistory();
  const location = useLocation();

  const accessRoute = useCallback(() => {
    let path = location.pathname.toUpperCase();
    const isAuthorized = Boolean(pages?.find((res) => path.includes(res)));
    // if (!isAuthorized && isAuth) return history.push("/home");
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    accessRoute();
  }, [accessRoute]);

  return (
    <Layout>
      <Sidebar />
      <Layout className="site-layout">
        <Navbar
          accountInfo={setAccountInfoModal}
          changePassword={setChangePasswordModal}
          logout={setLogoutModal}
        />
        {children}
      </Layout>

      {accountInfoModal && (
        <AccountInfoModal
          show={accountInfoModal}
          profile={staffProfile}
          onCancel={() => setAccountInfoModal(false)}
        />
      )}

      {changePasswordModal && (
        <ChangePasswordModal
          show={changePasswordModal}
          onCancel={() => setChangePasswordModal(false)}
        />
      )}

      {showLogoutModal && (
        <LogoutModal
          show={showLogoutModal}
          onCancel={() => setLogoutModal(false)}
        />
      )}
    </Layout>
  );
};

export default AppShell;
