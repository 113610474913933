import { types } from "./types";

const initialState = {
  loanTab: "loan request",
  payment: {
    list: [],
    requestId: null,
    phoneNumber: null,
  },
  duration: null,
  updateAmount: false,
};

const loanReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAN_TAB:
      return { ...state, loanTab: action.payload };
    case types.REQUEST_AMOUNT_STATE:
      return { ...state, updateAmount: action.payload };
    case types.DURATION_REQUEST:
      return { ...state, duration: action.payload };
    default:
      return state;
  }
};

export default loanReducer;
