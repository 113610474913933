import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import useWindowSize from "./windowSize";
import { useLocation, useHistory } from "react-router-dom";
import { getPremiumSubscription } from "../../modules/Subscription/store/actions";
import { premiumFeatureRecord } from "./helper";

/**
 *
 * @param {Form instance} form an antd form instance
 * @param {Array of Strings} optionalFields fields that are not required if any
 * @returns
 */

export const useFormValidation = (form, optionalFields) => {
  const [fields, setFields] = useState(form.getFieldsValue());
  const [valid, setValid] = useState(false);
  let optionals = optionalFields || [];

  const validate = useCallback(() => {
    setFields(form.getFieldsValue());
    let formFieldsValues = Object.values(
      form.getFieldsValue(
        Object.keys(form.getFieldsValue()).filter(
          (field) => !optionals.includes(field)
        )
      )
    );

    if (formFieldsValues.includes(undefined) || formFieldsValues.includes(""))
      return setValid(false);
    setValid(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    validate();
  }, [validate]);

  return { fields, valid, validate };
};

export const useAdminRole = () => {
  const userRole = useSelector((s) => s.auth.user?.role);
  const [admin, setAdmin] = useState(false);
  useEffect(() => {
    if (userRole === "ADMIN") setAdmin(true);
    else setAdmin(false);
  }, [userRole]);

  return admin;
};

export const useBusinessCategory = () => {
  const category = useSelector((s) => s.auth?.userCategory);
  const [isBanker, setIsBanker] = useState(false);
  useEffect(() => {
    if (category === "MFB") setIsBanker(true);
    else setIsBanker(false);
  }, [category]);

  return isBanker;
};

export const useIsMobileView = () => {
  const [mobile, setMobile] = useState(false);
  const { width } = useWindowSize();

  useEffect(() => {
    if (width < 570) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [width]);

  return mobile;
};

export const useUrlQuery = (key) => {
  const { search, state } = useLocation();
  const query = new URLSearchParams(search).get(key);
  return { query, state };
};

export const usePremiumType = ({ type, tab, text, target }) => {
  const renewalMessage = {
    show: true,
    title: `${text} Subscription Expired`,
    text: "Kindly renew to uncover valuable insights and make informed choices.",
    buttonText: "Renew Plan",
  };

  const freeTrialMessage = {
    show: false,
    title: `Try ${text} FREE for a Month!`,
    text: `Uncover valuable insights and make informed choices with ${text} 30-day trail.`,
    buttonText: "Start Your Free Trial",
  };

  const [modal, setModal] = useState(freeTrialMessage);
  const { premium = premiumFeatureRecord } = useSelector(
    (state) => state.business
  );
  const premiumType = premium[type];
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const isActivated =
    premiumType?.subscriptionStatus?.toUpperCase() === "ACTIVATED";
  const isEmpty = premiumType?.subscriptionStatus === null;

  function navigate() {
    history.push({
      pathname: "/subscription/feature-plan",
      search: `?type=${tab}`,
      state: { [target]: isEmpty },
    });
  }

  function close() {
    history.push("/home");
  }

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const { data } = await getPremiumSubscription({ type });
        if (data.length === 0) {
          setModal((prev) => ({ ...prev, show: true }));
        } else if (data[0]?.subscriptionStatus?.toUpperCase() === "ACTIVATED") {
          setModal((prev) => ({
            ...prev,
            show: false,
          }));
        } else {
          setModal(renewalMessage);
        }
      } catch (err) {
        console.log(err);
      }
      setIsLoading(false);
    }
    fetchData();
    // eslint-disable-next-line
  }, [type]);

  return {
    isLoading,
    isActivated,
    modal,
    history,
    premium,
    close,
    navigate,
  };
};
