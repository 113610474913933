import { premiumFeatureRecord } from "../../../common/utils/helper";
import { types } from "./types";

const initialState = {
  pageTab: "BUSINESS",
  terminals: {},
  createUserForm: false,
  premium: premiumFeatureRecord,
};

const businessReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TAB:
      return { ...state, pageTab: action.payload };

    case types.SET_TERMINALS:
      return { ...state, terminals: action.payload };

    case types.SHOW_AGGREGATOR_FORM:
      return { ...state, aggregatorForm: true };

    case types.HIDE_AGGREGATOR_FORM:
      return { ...state, aggregatorForm: false };

    case types.PREMIUM_BY_TYPE:
      const dataResponse = action.payload;

      const modifiedData = dataResponse.reduce((acc, item) => {
        return { ...acc, [item.type]: item };
      }, {});
      return {
        ...state,
        premium: {
          ...state.premium,
          ...modifiedData,
        },
      };

    default:
      return state;
  }
};

export default businessReducer;
