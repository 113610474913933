import React from "react";

const UserStatus = ({ status, className }) => {
  return (
    <>
      {status && (
        <div
          className={`px-2 py-2 rounded-lg text-xs font-semibold flex items-center whitespace-nowrap justify-center status ${className}`}
          style={
            status?.toLowerCase() === "active"
              ? { color: "#3359DE", backgroundColor: "#3359DE1a" }
              : {
                  color: "#E2164B",
                  backgroundColor: "#FDEAEF",
                }
          }
        >
          <p className="capitalize text-xs">{status}</p>
        </div>
      )}
    </>
  );
};

export default UserStatus;
