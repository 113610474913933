import React from "react";
import ModalShell from "./ModalShell";
import { Button } from "antd";
import cancelIcon from "../../../assets/icons/cancel.png";
import ImageAvatar from "../Avatars/Avatar";
import { BlackButton } from "../Buttons/Button";
import UserStatus from "../Status/UserStatus";
import { HumanFriendlyDate } from "../../utils/helper";
import { useHistory } from "react-router";

const AccountInfoModal = ({ show, onCancel, profile }) => {
  const history = useHistory();

  function goToTickets() {
    history.push("/support/tickets");
    onCancel();
  }

  return (
    <ModalShell
      style={{
        padding: 0,
      }}
      show={show}
      cancel={onCancel}
      closable={false}
    >
      <div className="bg-white pb-5 mb-5 overflow-hidden relative">
        <div className="w-full h-32 account-bd flex flex-col justify-center items-center ">
          <Button
            onClick={onCancel}
            className="bg-transparent hover:bg-transparent focus:bg-transparent absolute right-2 top-2 border-0"
          >
            <img className="w-3" src={cancelIcon} alt="cancel-icon" />
          </Button>
        </div>
        <div className="flex justify-evenly relative">
          <ImageAvatar
            style={{
              position: "absolute",
              bottom: 40,
              right: "65%",
            }}
            className="moblie-profile "
            profileImageId={profile.profileImageId}
            size={140}
          />
          <div className="mt-5 xxsscreen ml-20">
            <p className="font-bold break-all text-xl capitalize">
              {profile.firstName} {profile.lastName}
            </p>
            <p className="mikro-gray">{profile.email}</p>
            <UserStatus status={profile.status} />
          </div>
        </div>
        <div className="md:p-10 py-8 pl-5">
          <div className="flex mb-3">
            <h4 className="w-2/4 mikro-gray "> Phone Number </h4>

            <h4 className="w-3/6 font-bold">{profile.phoneNumber}</h4>
          </div>
          <div className="flex mb-3">
            <h4 className="w-2/4 mikro-gray "> Username </h4>

            <h4 className="w-3/6 font-bold">{profile.username}</h4>
          </div>
          {profile?.department && (
            <div className="flex mb-3">
              <h4 className="w-2/4 mikro-gray"> Department </h4>

              <h4 className="w-3/6 font-bold">
                {" "}
                {profile?.department?.replace("_", " ")}{" "}
              </h4>
            </div>
          )}

          <div className="flex mb-3">
            <h4 className="w-2/4 mikro-gray"> Designation </h4>

            <h4 className="w-3/6 font-bold">
              {" "}
              {profile?.role?.replace("_", " ") || "AGGREGATOR"}{" "}
            </h4>
          </div>

          <div className="flex mb-3">
            <h4 className="w-2/4 mikro-gray"> Date Created </h4>

            <h4 className="w-3/6 font-bold">
              {HumanFriendlyDate(profile.timeCreated)}
            </h4>
          </div>
          <div className="flex flex-col items-center mt-5">
            <BlackButton onClick={goToTickets} className="w-2/5 text-xs">
              View Tickets
            </BlackButton>
          </div>
        </div>
      </div>
    </ModalShell>
  );
};
export default AccountInfoModal;
