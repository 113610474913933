import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { authRoutes as routes } from "../routes/routes";

const AuthContainer = () => {
  return (
    <Switch>
      <Suspense fallback={null}>
        {routes.map((route, i) => (
          <Route
            key={i}
            path={route.path}
            exact={route.exact || false}
            component={route.Component}
          />
        ))}
      </Suspense>
    </Switch>
  );
};

export default AuthContainer;
