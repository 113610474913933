import React, { useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import AuthShell from "./AuthShell";
import { Form, notification } from "antd";
import { loginUser, setFormInput } from "./store/actions";
import { useDispatch } from "react-redux";
import types from "./store/types";
import AuthButton from "../../common/components/Buttons/AuthButton";
import BackButton from "../../common/components/Buttons/BackButton";
import CustomInput from "../../common/components/forms/CustomInput";
import CustomPasswordInput from "../../common/components/forms/CustomPasswordInput";
import { useFormValidation } from "../../common/utils/Hooks";
import bg from "../../assets/backgrounds/login-bg.png";
import { UserType, validateEmailAndUsername } from "../../common/utils/helper";

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState();
  const isAggregator = useMemo(
    () => window.location.hostname.includes("aggregator"),
    []
  );

  const onFinish = async (values) => {
    const newValue = {
      ...values,
      domainType: isAggregator ? UserType.AGGREGATOR : UserType.BANKER,
    };

    try {
      setLoading(true);
      await loginUser(newValue);
      notification["success"]({
        message: "Login Successful",
        duration: 5,
      });
      setTimeout(() => {
        history.push("/home");
      }, 100);
      setLoading(false);
    } catch (err) {
      if (err.response?.status === 403 || err.response?.status === 406) {
        dispatch({
          type: types.SET_USER,
          payload: { id: err.response?.data?.data?.userId },
        });
        notification.error({
          message: "Oops! Your email is not verified.",
          duration: 6,
        });
        history.push(
          "/verify-email/" + err.response?.data?.data?.verificationId
        );
      } else if (err.response?.status === 451) {
        dispatch({
          type: types.SET_BROWSER_REQUEST_ID,
          payload:
            err.response?.data?.message?.browserId ||
            err.response?.data?.message,
        });
        dispatch({
          type: types.SET_USER,
          payload: {
            profileImageId: err.response?.data?.message?.profileImageId || "",
          },
        });
        notification.error({
          message: "This device is not recognized in our system.",
          duration: 6,
        });
        history.push("/verify-device", { values });
      } else {
        notification.error({
          message: err.response?.data?.message || err.message || "Login Failed",
          duration: 10,
        });
        setLoading(false);
      }
    }
  };

  const { fields, valid, validate } = useFormValidation(form, []);

  return (
    <AuthShell title={""} description={""} background={bg}>
      <div className="">
        {!isAggregator && (
          <div className="mb-5 text-left">
            <BackButton />
          </div>
        )}
        <div>
          <h2 className="text-4xl font-bold mb-2">Welcome back!</h2>
          <p className="mikro-gray mb-16">Sign in to continue</p>
        </div>
        <Form
          name="basic"
          onFinish={onFinish}
          layout="vertical"
          form={form}
          onValuesChange={validate}
        >
          <Form.Item name="username" placeholder="Email">
            <CustomInput
              id="username"
              name="username"
              label="Email"
              placeholder="Email/Username"
              rules={[
                {
                  required: true,
                  message: "Please input your email or username!",
                },
                { validator: validateEmailAndUsername },
              ]}
              hasFeedback
            />
          </Form.Item>

          <Form.Item
            className="mt-8"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <CustomPasswordInput
              id="password"
              name="password"
              label="Password"
              placeholder="Password"
              hasValue={fields.password?.length}
            />
          </Form.Item>

          <div className="flex items-center justify-between mb-5">
            <Link
              className="text-black font-semibold text-sm"
              to="/forgot-password"
            >
              Forgot password?
            </Link>
          </div>

          <div className="flex w-full justify-between mt-16 mb-5">
            <AuthButton
              loading={loading}
              className=" w-full"
              htmlType="submit"
              disabled={!valid}
            >
              LOG IN
            </AuthButton>
          </div>
          {isAggregator ? null : (
            <div className="flex justify-center">
              <div className="flex items-center mx-auto">
                <p className="text-sm text-gray-400 mr-2">New here?</p>
                <Link
                  className="mikro-blue font-semibold text-sm"
                  to="/signup-type"
                  onClick={() => dispatch(setFormInput({}))}
                >
                  Create an Account
                </Link>
              </div>
            </div>
          )}
        </Form>
      </div>
    </AuthShell>
  );
};

export default Login;
